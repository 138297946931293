import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout, { Player } from '../components/layout';
import InstallPWA from '../components/pwa/InstallPWA';
import SEO from '../components/seo';
import trackEvent from '../components/analytics';

// import Background from '../images/splash.jpg';
// import Background from '../images/splash_1.jpg';
import Background from '../images/background_home.jpg';
// import Sound from '../audio/fr/voixoff_FR_1.mp3';
import Sound from '../audio/VoixOff_intro_FR_01.mp3';

// background-size: cover;

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;
  background-image: url(${Background});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
`;

const ViewButtons = styled.div`
  display: flex;
  flex: 0 1 auto;
  margin-bottom: 0.5rem;
  margin-top: 5rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  position: relative;
`;

const ViewButtonDi = styled(Button)`
  margin: 1rem 9rem;
  min-width: 100px;
  background-color: #798f1c;
  border-color: #798f1c;
  width: 100px;
  animation: bounceIn;
  animation-duration: 2s;
  a {
    color: #fff;
  }
`;

const ViewButtonFr = styled(Button)`
  position: absolute;
  top: -120px;
  margin: 1rem 9rem;
  min-width: 100px;
  animation: bounceIn;
  animation-duration: 2s;
  a {
    color: #fff;
  }
`;
const ViewButtonBa = styled(Button)`
  margin: 1rem 9rem;
  min-width: 100px;
  animation: bounceIn;
  animation-duration: 2s;
  a {
    color: #fff;
  }
`;

const Splash = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO pathname="/" />
    <ViewArticle>
      <ViewButtons>
        <ViewButtonDi
          color="success"
          onClick={() => {
            trackEvent('Welcome Page', 'Clicked on Dioula', 'yes');
          }}
        >
          <Link to="/di"> Dioula</Link>
        </ViewButtonDi>
        <ViewButtonFr
          color="info"
          onClick={() => {
            trackEvent('Welcome Page', 'Clicked on French', 'yes');
          }}
        >
          <Link to="/fr">Français</Link>
        </ViewButtonFr>
        <ViewButtonBa
          color="ba-info"
          onClick={() => {
            trackEvent('Welcome Page', 'Clicked on Baoule', 'yes');
          }}
        >
          <Link to="/ba">Baoulé</Link>
        </ViewButtonBa>
      </ViewButtons>
      <InstallPWA />
    </ViewArticle>
    <Player isIndex sound={Sound} />
  </Layout>
);

export default Splash;
